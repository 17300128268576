<template>
    <rs-dialog :visible.sync="visible" :title="title">
      <rs-form :model="custom" ref="Form" @submit.native="submit">
          <rs-form-item label="年份ID" prop="yeahid" :class="{'is-filled':custom.yeahid}" :rules="[{required:true,message:'年份必填'}]">
              <rs-input v-model="custom.yeahid"></rs-input>
          </rs-form-item>
          <rs-form-item label="名称" prop="name" :class="{'is-filled':custom.name}" :rules="[{required:true,message:'名称必填'}]">
              <rs-input v-model="custom.name"></rs-input>
          </rs-form-item>
          <rs-form-item label="电话" :class="{'is-filled':custom.tel}">
              <rs-input v-model="custom.tel" type="number"></rs-input>
          </rs-form-item>
          <rs-form-item label="地址" prop="address" :class="{'is-filled':custom.address}" :rules="[{required:true,message:'地址必填'}]">
              <rs-input v-model="custom.address"></rs-input>
          </rs-form-item>
          <rs-form-item label="状态" prop="status" :class="{'is-filled':custom.status}" style="height:46px">
              <rs-select v-model="custom.status">
                <option value="0">未完成</option>
                <option value="1">已完成</option>
                <option value="2">已结账</option>
              </rs-select>
          </rs-form-item>
          <rs-form-item label="日期" prop="time" :rules="[{required:true,message:'日期必填'}]" class="is-focused">
              <rs-input v-model="custom.time" type="date"></rs-input>
          </rs-form-item>
          <rs-form-item>
              <rs-button color="info" type="submit">提交</rs-button>
          </rs-form-item>
      </rs-form>
    </rs-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      id: null,
      item: null,
      custom: {},
      title: '添加新客户信息'
    }
  },
  methods: {
    handle (id, item) {
      this.id = id
      this.item = item
      this.custom = { yeahid: '2023-', status: 0 }
      if (id !== 0) {
        this.title = '编辑客户信息'
        this.$api.custom.index({ id: id }, (data) => {
          this.custom = data.data
        })
      }
      this.visible = true
    },
    submit (e) {
      e.preventDefault()
      this.$refs.Form.validate(isValidate => {
        if (isValidate) {
          if (this.id !== 0) {
            this.$api.custom.update(this.custom, (data) => {
              if (data.code === 200) {
                this.$emit('customUpdate', 'custom', this.item, data.data)
                this.visible = false
              }
            })
          } else {
            this.$api.custom.insert(this.custom, (data) => {
              if (data.code === 200) {
                this.$emit('customUpdate', 'custom', 'add', data.data)
                this.visible = false
              }
            })
          }
          this.visible = false
        } else {
        }
      })
    }
  }
}
</script>
