<template>
  <rs-row>
    <!-- current_page:当前页面 last_page:页面数 per_page:每页数量 total:总数量 -->
    <rs-col :md='12'>
      <rs-card icon="person" title="客户列表">
        <template slot="tools">
          <rs-button color="info" is_sm is_link>
            客户总数:{{customCount.total}}
          </rs-button>
          <rs-button color="info" is_sm @click="get_status(0)">
            未完成:{{customCount.incomplete}}
          </rs-button>
          <rs-button color="info" is_sm  @click="get_status(1)">
            已完成:{{customCount.done}}
          </rs-button>
          <rs-button color="info" is_sm  @click="get_status(2)">
            已结账:{{customCount.paid}}
          </rs-button>
          <rs-button color="info" is_sm  @click="get_status(3)">
            已核对:{{customCount.check}}
          </rs-button>
          <rs-button @click="$refs.custom.handle(0)" is_link is_sm>
            添加客户
          </rs-button>
        </template>
        <rs-table-striped
        @search="get_table"
        :data="customData"
        :column="{ yeahid: '年度ID', name: '名称', address: '地址', tel: '电话', time: '日期' }"
        isSearch
        >
          <template slot-scope="scope">
            <td class="td-actions text-right">
              <rs-button color="info" @click="details(scope.row)">
                <i class="material-icons">person</i>
              </rs-button>
              <rs-button color="success" @click="$refs.custom.handle(scope.row.id,scope.column)">
                <i class="material-icons">edit</i>
              </rs-button>
              <rs-button color="danger" @click="customDel(scope.row.id,scope.column)">
                <i class="material-icons">close</i>
              </rs-button>
            </td>
          </template>
        </rs-table-striped>
      </rs-card>
    </rs-col>
      <custom @customUpdate="updateItem" ref="custom"></custom>
  </rs-row>
</template>

<script>
import custom from './curd/custom.vue'
export default {
  components: { custom },
  data () {
    return {
      customData: {},
      customCount: {},
      status: 0
    }
  },
  created () {
    // 初始化客户列表
    this.get_table('', 1)
    // 获取统计数据
    this.get_custom_count()
  },
  methods: {
    updateItem (type, item, data) {
      switch (type) {
        case 'custom':
          if (item === 'add') {
            this.customData.data.unshift(data)
          } else {
            this.customData.data.splice(item, 1, data)
          }
          this.get_custom_count()
          break
        default:
          break
      }
    },
    // 按客户状态分类显示
    get_status(status) {
      this.status = status
      this.get_table('', 1)
    },
    // 获取按客户分类数量统计
    get_custom_count() {
      this.$api.custom.count(
        null,
        (data) => { this.customCount = data.data }
      )
    },
    // 获取客户列表
    get_table (sea, page) {
      this.$api.custom.list(
        { current_page: page, search: sea, status: this.status }, (data) => {
          this.customData = data.data
        })
    },
    // 触发详情页
    details (row) {
      this.$router.push({
        path: '/custom/details',
        query: { id: row.id }
      })
    },
    customDel (id, item) {
      // 提示确认删除
      this.$tools.swal.del(() => {
        // 删除客户
        this.$api.custom.del({ id: id }, (data) => {
          if (data.code === 200) {
            this.customData.data.splice(item, 1)
            this.$tools.notify.toast('删除提示', '删除成功', 'success')
          } else {
            this.$tools.notify.toast('删除提示', data.data.message, 'success')
          }
        })
      })
    }
  }
}
</script>

<style>

</style>
